/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
@import './less/index.less';
@import './less/variables.less';
@import './less/index.less';

@datasource-sql-expression-width: 315px;
* {
  margin: 0;
  padding: 0;
}
span,
div,
i {
  &:focus {
    outline: none;
  }
}
a:focus {
  outline: none;
}
.alert.alert-danger > .debugger {
  color: @danger;
}

.no-wrap {
  white-space: nowrap;
}

input.form-control {
  background-color: @lightest;
}

.chart-header a.danger {
  color: @danger;
}

.disabledButton {
  pointer-events: none;
}

.col-left-fixed {
  width: 350px;
  position: absolute;
  float: left;
}

.col-offset {
  margin-left: 365px;
}

.slice_description {
  padding: 8px;
  margin: 5px 0;
  border: 1px solid @gray-light;
  background-color: @gray-bg;
  border-radius: @border-radius-large;
  font-size: @font-size-s;
}

.slice_info {
  cursor: pointer;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  transition: all 300ms ease;
}
.sopact.grid-container {
  max-width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  padding: 24px 32px;
  background: #fff;
  max-height: 100px;
}
.dashboard--editing .sopact.grid-container {
  max-width: 65%;
}
.padded {
  padding: 10px;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.intable-longtext {
  max-height: 200px;
  overflow: auto;
}

.container-fluid {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

input[type='checkbox'] {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.widget-is-cached,
.d-none {
  display: none;
}
.project-card-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.header span.label {
  margin-left: 5px;
  margin-right: 5px;
}

.notbtn {
  cursor: default;
  box-shadow: none;
  border: 1px solid @gray;
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

span.title-block {
  background-color: @gray-bg;
  border-radius: @border-radius-large;
  padding: 6px 12px;
  margin: 0px 10px;
  font-size: @font-size-xl;
}

.nvtooltip {
  table td {
    font-size: @font-size-s !important;
  }
}

div.navbar {
  .dropdown-menu .fineprint {
    line-height: 1.5rem;
    padding: 10px 20px 5px 20px;
    color: @gray-light;
    font-size: @font-size-m;

    div {
      white-space: nowrap;
    }
  }
}

.datasource {
  form {
    div.form-control,
    input.form-control {
      margin-bottom: 5px !important;
    }
  }

  .tooltip-inner {
    max-width: 350px;
  }
}

img.viz-thumb-option {
  width: 100px;
  border: 1px solid @gray;
  margin-right: 5px;
  border-radius: @border-radius-large;
}

.select2-drop.bigdrop .select2-results {
  max-height: 700px;
}

.chart-header .header-text {
  font-size: @font-size-xl;
  line-height: 22px;
  padding-bottom: 8px;
  border-bottom: 1px solid @gray;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

#is_cached {
  display: none;
}

.slice_container.faded {
  opacity: 0.2;
}

.navbar .alert {
  padding: 5px 10px;
  margin-top: 8px;
  margin-bottom: 0;
}

.table-condensed {
  font-size: @font-size-s;
}

.table-condensed input[type='checkbox'] {
  float: left;
}

table.table-no-hover tr:hover {
  background-color: initial;
}

.editable-title input {
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  cursor: initial;
}

.editable-title textarea {
  outline: none;
  background: transparent;
  box-shadow: none;
  cursor: initial;
  border: 1px solid @gray;
  border-radius: @border-radius-normal;
}

.editable-title input[type='text'] {
  border: 1px solid @gray;
  border-radius: @border-radius-normal;
  padding: 2px;
}

.editable-title.datasource-sql-expression {
  font-feature-settings: @font-feature-settings;
  font-family: @font-family-monospace;
  display: inline-block;
  min-width: @datasource-sql-expression-width;
  width: 100%;
}

.editable-title.datasource-sql-expression input {
  width: 95%;
  padding-bottom: 5px;
}

.editable-title.datasource-sql-expression textarea {
  min-height: 100px;
  width: 95%;
}

.editable-title input[type='button'] {
  border-color: transparent;
  background: transparent;
  font-size: inherit;
  white-space: normal;
  text-align: left;
  cursor: initial;
}

.editable-title.editable-title--editable input[type='button'] {
  cursor: pointer;
}

.editable-title.editable-title--editing input[type='button'] {
  cursor: text;
}

.anchor-link-container {
  position: absolute;

  .btn.btn-sm,
  .btn.btn-sm:active {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    box-shadow: none;
  }
  .fa.fa-link {
    position: relative;
    top: 2px;
    right: 0;
    visibility: hidden;
    font-size: @font-size-s;
    text-align: center;
    vertical-align: middle;
  }
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sourcecredentials {
  background: #fff;
  left: 33%;
  top: -12px;
}
.sourcecredentialsoutter {
  border: 1px solid #d9d9d9;
  min-height: 100px;
  padding: 20px;
  margin: 0 auto 20px;
}
.dashboard-component.dashboard-component-header .anchor-link-container {
  .fa.fa-link {
    font-size: @font-size-l;
  }
}

.dashboard-component.dashboard-component-header:hover {
  .anchor-link-container {
    cursor: pointer;

    .fa.fa-link {
      visibility: visible;
    }
  }
}

.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px !important;
}

.m-r-3 {
  margin-right: 3px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 2rem;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-b-10 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 2%;
}
.mb-3 {
  margin-bottom: 2.6%;
}
.m-l-2 {
  margin-left: 2px;
}
.m-l-4 {
  margin-left: 4px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-25 {
  margin-left: 25px;
}

.p-l-0 {
  padding-left: 0;
}

.p-t-8 {
  padding-top: 8;
}

.p-r-2 {
  padding-right: 2;
}
.px-20 {
  padding: 0 20px;
}

.gap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 24px;
  column-gap: 24px;
}
/** not found record **/
.panel b {
  display: inline-block;
  width: 98%;
  padding: 2rem;
  margin: 0 1% 20px 1%;
  background: @gray-bg;
}
.title {
  color: #353ea9;
  font-weight: 600;
}
.right-pop-title .title {
  margin-right: 10%;
}
.text-normal {
  font-weight: normal !important;
}
.sourc p {
  color: #353da9;
  font-weight: 600;
}
.sync-icon {
  font-size: 24px;
  margin-right: 5px;
}
/** table on both sides of the gap **/
@media screen and (max-width: 767px) {
  .panel .table-responsive {
    width: 98%;
  }
}

.list-container {
  position: relative;
}

.list-search-container {
  position: relative;
}

.list-search-container .dropdown-toggle {
  position: absolute;
  top: -43px;
  right: 25px;
  border: 0;
  padding: 0 18px;
}

.list-search-container .fa-filter {
  position: relative;
  left: -8px;
}

.list-search-container .dropdown-menu {
  top: -19px;
  right: 0;
  left: auto;
  float: none;
}

.list-container .pagination-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.list-container .pagination-container .pagination {
  margin: 0 15px;
}

.list-container .pagination-container strong {
  margin-right: 5px;
}

.list-container .list-add-action {
  position: absolute;
  top: -30px;
  right: 15px;
}

.list-container .form-actions-container {
  padding: 0 0 20px 10px;
  display: inline;
}

.form-actions-container button {
  display: flex;

  .caret {
    margin: 0 8px;
  }
}

.list-container .filter-action {
  margin: 10px 10px 0 10px;
  padding-bottom: 15px;
}

.list-add-action .btn.btn-sm {
  padding: 6px 6px;
  font-size: @font-size-xs;
  line-height: 2px;
  border-radius: 50%;
  box-shadow: 2px 2px 4px -1px fade(@darkest, @opacity-light);
  i {
    width: 10px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.text-transparent {
  color: transparent;
}

.pointer {
  cursor: pointer;
}

.popover {
  max-width: 500px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

g.annotation-container {
  line {
    stroke: @brand-primary;
  }

  rect.annotation {
    stroke: @brand-primary;
    fill-opacity: 0.1;
    stroke-width: 1;
  }
}

.stroke-primary {
  stroke: @brand-primary;
}

.reactable-header-sortable {
  position: relative;
  padding-right: 40px;

  &::after {
    font: normal normal normal 14px/1 FontAwesome;
    content: '\f0dc';
    margin-left: 10px;
    color: @brand-primary;
  }
}

.reactable-header-sort-asc::after {
  content: '\f0de';
  color: @brand-primary;
}

.reactable-header-sort-desc::after {
  content: '\f0dd';
  color: @brand-primary;
}

tr.reactable-column-header th.reactable-header-sortable {
  padding-right: 17px;
}

.align-right {
  text-align: right;
}

td.filtered {
  background-color: lighten(desaturate(@brand-primary, 50%), 50%);
}

.table-name {
  font-size: @font-size-l;
}
.select2-container-multi {
  width: 100% !important;
}

/*
  Hides the logo while loading the page.
  Emotion styles will take care of the correct styling
  */
.navbar-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: unset;
  // padding: 5px 10px;
}
.control-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

// Making native radio buttons use brand color
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid @gray;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 5px solid @brand-primary;
}
hr {
  border-top: 1px solid @gray-light;
}
.ace_gutter-cell.ace_error {
  background-image: url('../images/icons/error_solid_small_red.svg') !important;
  background-position: -2px center !important;
}
.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.justify-center {
  justify-content: center !important;
}
.items-center {
  align-items: center !important;
}
.text-center {
  text-align: center;
}
.text-dark {
  color: #000 !important;
}
.text-dark a {
  text-decoration: underline;
  color: #ffffff80 !important;
}
.h-100 {
  height: 100vh;
}
.h-full {
  height: 100% !important;
}
.h-90 {
  height: 90vh;
}
.w-full {
  width: 100vw;
}
.w-100 {
  width: 100%;
}
.m-auto {
  margin: auto;
}
.box {
  background: url(../images/watermark.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 150px 250px;
  height: 100%;
}
.login-logo {
  width: 60%;
}
.mainbox {
  background-color: #353ea9;
  color: #fff !important;
}
#loginbox .panel-title {
  color: #fff !important;
  font-size: 26px;
  border-bottom: none !important;
}
.text-white {
  color: #fff !important;
  text-transform: capitalize !important;
}
#loginbox .form-control {
  background-color: #353ea9 !important;
}
::placeholder {
  color: #fff;
}
#datasource-table .ant-table-wrapper {
  width: 95vw;
  margin: auto;
}
#loginbox .panel-body,
#loginbox .panel-heading {
  width: 70%;
}
.login {
  width: 120px !important;
  margin: 15px 0 0 auto;
  background: #fff !important;
  color: #000;
  text-transform: capitalize !important;
  padding: 6.5px 18px !important;
}
.reset_password {
  width: 200px !important;
  margin: 15px 0 0 auto;
  background: #fff !important;
  color: #000;
  text-transform: capitalize !important;
  padding: 6.5px 18px !important;
}
.justify-between {
  justify-content: space-between;
}
.w-95 {
  width: 95%;
}
.w-90 {
  width: 90%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.forget {
  text-decoration: underline;
}
.register {
  margin-top: 12rem;
}
.tableh {
  width: 70vw;
  background: #fff;
  margin-bottom: 0;
}
.tableh tr th,
tableh tr td {
  padding: 15px;
}
.tableh tr {
  border-bottom: 1px solid #d3d3d3;
}
.p-4 {
  padding: 2rem;
}
.p-3 {
  padding: 12px;
}
.pb-16 {
  padding-bottom: 16px;
}
.outerdiv {
  display: flex;
}
// .outerdivwith{
//   display: flex;
//   overflow-y: scroll;
// }
.outerdiv .btn-blue,
.ant-modal-confirm .ant-modal-confirm-btns {
  font-size: 12px;
  font-weight: 600;
}
.btn-blue {
  background: #353ea9 !important;
  color: #ffff;
  padding: 0 18px !important;
  border: none;
  border-radius: 5px;
  height: 32px;
}
.btn-blue:hover,
.btn-blue:focus {
  background: #353ea9;
  color: #ffff;
}
.pop-up .ant-modal-content {
  min-height: auto;
}
.project-pop .ant-modal-body {
  padding: 24px !important;
}
.pop-up .ant-modal-body {
  padding: 24px 24px 0;
}
.justify-content-around {
  justify-content: space-around;
}
.pop-up .ant-modal-close {
  top: 1.05%;
}
.pop-up h3,
.right-pop-title .title {
  margin-top: auto;
  margin-bottom: auto;
}
.pop-up .ant-modal-footer {
  border-top: none;
  text-align: left;
  padding: 16px;
}
.btn-blue-popup {
  background: #353da9 !important;
  padding: 5px 10px !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.btn-blue-popup {
  padding: 5px 10px !important;
  border: none !important;
  border-radius: 5px !important;
}
.sync_table .thead {
  background: rgb(229, 229, 229);
  height: 50px;
  padding: 10px;
}
.btn-blue-popup span {
  color: #fff !important;
}
.btn-white-popup span,
.pop-up .ant-input-group-addon {
  color: #000 !important;
}
.search-box-btn .ant-input::placeholder {
  font-size: 14px;
}
.btn-white {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-white .fa {
  padding: 0 5px;
  color: #353ea9;
}
.inputth .fa {
  padding: 0 5px;
  color: #353fa978;
}
label.title {
  font-size: 16px;
  margin: 0 16px;
}
.d-block {
  display: block;
}
.status-btn {
  margin: 0 5px 0 2em;
  border-radius: 5px 0 0 5px !important;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
}
// .statustable thead {
//   display: none;
// }
.statustable tbody tr {
  border: 0.2px solid #e5e5e5;
}
.statustable .ant-table-tbody > tr.ant-table-row.bg-success:hover > td {
  background: #ebfdf5;
}
.statustable .ant-table-tbody > tr.ant-table-row.bg-danger:hover > td {
  background: #ff5e7b38;
}

// .statustable td:last-child {
//   text-align: right;
// }
.pop-up .statustable tr td,
.pop-up .statustable tr th {
  padding: 10px;
}
.conn-btn {
  border-radius: 0 5px 5px 0 !important;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
}
.search-box-btn .ant-input-search-button,
.prefix-input .ant-input {
  border-width: 1px 1px 1px 0;
}
.search-box-btn .ant-input,
.search-box-btn .ant-btn {
  background-color: #f1f4f6;
  border-radius: 5px;
}
.form-drop {
  width: 100% !important;
}
.prefix-input,
.select-sync,
.select-syncp {
  width: auto !important;
  min-width: auto !important;
}
.select-syncp {
  margin: 0px;
}
.prefix-input .ant-input-group-addon {
  border-width: 1px 0 1px 1px;
}
.btn-update-sync {
  margin-left: 2%;
  padding: 8px !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.btn-update-sync span,
.btn-blue span {
  color: #fff !important;
}
.pop-up span,
.pop-up a {
  color: #353da9;
}
.pop-up span {
  font-weight: 600;
}
.pop-up .ant-radio-checked .ant-radio-inner {
  border-color: #353da9;
}
.pop-up .ant-radio-checked .ant-radio-inner {
  background-color: #353da9;
}
.pop-up .ant-radio-inner::after {
  background: #fff;
}
.ant-radio-wrapper span,
.select-sync .ant-select-selection-item,
.select-sync .ant-select-arrow .anticon,
.select-sync1 .ant-select-selection-item {
  color: #000;
  font-weight: normal;
}
.btn-source {
  // width: 16vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.select-sync .ant-select-selector,
.form-drop .ant-select-selector {
  height: 42px !important;
  padding: 5px 10px !important;
  background: #fafafa !important;
  color: #000 !important;
}
.ant-input-group:hover .ant-input-group-addon,
.ant-input-group:focus-visible .ant-input-group-addon {
  border-color: #555fb5;
}

.select-sync .ant-select-selection-item {
  display: flex;
  align-items: center;
}
.time {
  font-size: 20px;
  margin: 0 10px;
}
.searchinput {
  width: 100%;
  border: none;
}
.searchinput::placeholder {
  color: #858585 !important;
  font-size: 14px;
  font-weight: normal;
}
.searchinput:focus-visible {
  border: none;
  outline: none;
}
.align-center {
  align-items: center;
}
.check {
  margin: 0 10px !important;
}
.fa-angle-down {
  border: 1px solid #000;
  border-radius: 57px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabledatatitle {
  font-weight: 600;
}
.red {
  color: #e04355 !important;
}
.exceldiv {
  margin: auto 0;
  display: block;
}
.excel {
  font-size: 25px;
  color: #1fa462;
  width: 20px;
}
.excel-source {
  font-size: 30px;
  color: #1fa462;
  width: 25px;
}
.facircle .fa {
  font-size: 10px;
}
.label {
  background: #d8dcf0;
  border-radius: 20px;
  padding: 5px 10px;
  color: #353ea9;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.sync {
  margin-top: 5px;
  font-size: 12px;
}
.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  background-color: #fff;
  padding: 16px 0;
}
.mxy-3 {
  margin: 0 0.3rem;
}
.usergrid {
  width: 33px;
  height: auto;
  border-radius: 50px;
  position: absolute;
}
.form-disable {
  background: #efefef !important;
}
.user1 {
  right: 100%;
}
.user2 {
  right: 76%;
}
.user3 {
  right: 50%;
}
.user4 {
  right: 25%;
}
.usernum {
  width: 30px;
  height: 30px;
  background: #b2b3b5;
  border-radius: 50px;
  color: #000;
  position: absolute;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectcardes {
  height: 19px;
}
.projectcardes,
.projectcarddate,
.newcardp,
.project-detail .form-input::placeholder {
  color: #b2b3b5;
}
.form-input::placeholder {
  font-size: 14px;
}
.newcardp {
  font-size: 18px;
}
.carddoticon {
  position: absolute;
  top: 2%;
  right: 2%;
  color: #fff;
}
.greenbtn {
  background: #0fb87f;
  color: #fff;
  width: 260px;
  height: 30px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.justify-space {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}
.text-right {
  text-align: right;
}
.card {
  width: 500px;
  box-shadow: 0px 0px 30px #7f89a14d;
}
.p-5 {
  padding: 0 2rem 2rem;
}
.mx-4 {
  margin: 0 1.5rem;
}
.mx-3 {
  margin: 0 1rem;
}
.my-3 {
  margin: 1rem 0 !important;
}
.border-top {
  border-top: 2px solid #353da9;
}
.project-detail .form-input {
  border: 1px solid #d2d2d2;
  padding: 0 10px;
  width: 100%;
  height: 30px;
  background: #fff;
}
::placeholder {
  font-size: 14px;
}
.mb-5 {
  margin-bottom: 3rem;
}
.project-detail .text-gray {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 12px;
  border: none;
  background: transparent;
}
.add-new-card {
  width: 250px;
  min-height: 343px;
  height: 100%;
  border: 1px dashed #c7c7c7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
}
.project-detail textarea.text-gray:focus {
  border: none;
  box-shadow: none;
}
.btn {
  border: none;
  // padding: 0 18px;
  border-radius: 5px;
}
.filter-action .btn-sm {
  height: 32px;
}
.project-detail-card {
  height: auto;
  padding: 10px 5px;
  width: 100%;
}
.icon-card {
  width: 20px;
  height: 20px;
}
.icon-card-cross {
  border-radius: 5px;
  border: 1px solid #b2b3b5;
}
.table-btn {
  width: 70vw;
  justify-content: end;
  margin-top: 20px;
}
.ant-table-thead > tr > th {
  background: #fff !important;
  border-bottom: none !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px !important;
}
.ant-table-thead > tr > th:first-child,
.ant-table-tbody > tr > td:first-child {
  padding: 8px 8px 8px 16px !important;
}
.ant-table-column-sorters {
  padding: 0 !important;
}
.leftdiv {
  width: 50px;
  background: #353da9;
  min-height: 100vh;
}
.pop-up .ant-table-tbody > tr > td {
  padding: 5px 16px;
}
.projecttable {
  width: 100%;
}
.projecttable .ant-table-row .action {
  visibility: hidden;
  opacity: 0;
}
.projecttable .ant-table-row:hover .action {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  color: #666666;
}
.projecttable .ant-table-pagination.ant-pagination {
  padding: 16px 20px;
  background: transparent;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  background: none !important;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  background: #fff;
}
.ant-pagination-item {
  border: none !important;
}

.ant-pagination-item-active {
  color: #ffffff !important;
  cursor: default;
  background-color: #353da9 !important;
  a {
    color: #ffffff !important;
  }
}
.ant-pagination-prev,
.ant-pagination-next {
  a {
    color: #000;
  }
}
.ant-table-thead {
  > tr {
    > th {
      color: #333333 !important;
      font-weight: bold !important;
      font-size: 14px;
    }
  }
}
.expandtable .ant-table-thead {
  > tr {
    > th {
      font-size: 12px;
    }
  }
}
.text-bold {
  font-weight: bold;
}
.header {
  font-weight: 700;
  margin-right: 12px;
  text-align: left;
  font-size: 18px;
  padding: 12px;
  display: inline-block;
  line-height: 36px;
}
// media query for the userdiv
@media screen and (min-width: 1440px) {
  .user1 {
    right: 25%;
  }
  .user2 {
    right: 21%;
  }
  .user3 {
    right: 17%;
  }
  .user4 {
    right: 13%;
  }
  .usernum {
    right: 5%;
  }
}
@media screen and (max-width: 768px) {
  .box {
    background: none;
    height: 0;
  }
  .box img {
    width: 100%;
  }
}
@media screen and (max-width: 426px) {
  .box {
    background: none;
  }
  .box .h-90 {
    display: none;
  }
  .box img {
    width: 40%;
  }
}
.controls .input-group input:focus,
.controls .input-group input {
  color: #fff;
}
.controls .input-group input:focus {
  border-color: #fff !important;
}
.kobo {
  color: #2091eb;
  font-size: 25px;
}
.salesforce {
  color: orange;
  font-size: 25px;
}
.ant-tabs-nav-wrap {
  padding: 0 15px;
}

.css-auk1s1 {
  .superset-list-view {
    .header {
      .controls {
        label {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.css-1qynd6y {
  .menu {
    .ant-menu-item {
      li {
        a {
          font-size: 14px !important;
          font-weight: 600;
        }
      }
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #353da9 !important;
  }
}

.dashboard-header .dashboard-component-header .editable-title,
.text-sucess {
  color: #353da9;
}

.css-auk1s1 .superset-list-view {
  margin: 0 !important;
}
.mb-0 {
  margin: 0;
}
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background-color: #353da9 !important;
  color: #fff !important;
}
.action-button:hover,
.action .anticon:hover {
  color: #353da9;
}
.ant-input-search-button span {
  color: #fff;
  font-weight: 500 !important;
}
.ant-input-search-button span svg {
  color: #000;
}
.search-icon.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 5px 5px 0 !important;
}
.sheet-table
  .ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: none !important;
}
.sheet-table .ant-table {
  margin: 0 !important;
}
.sheet-table .ant-table .ant-table {
  max-height: 200px !important;
  overflow-y: auto;
}
.ant-input-search-with-button span,
.ant-input {
  font-weight: 500 !important;
}
.ant-select-selection-placeholder {
  color: #bfbfbf !important;
  font-weight: 500 !important;
}
.ant-select-selection-item {
  color: unset !important;
  font-weight: 500 !important;
}
.ant-btn[disabled] > * {
  color: #858585 !important;
}
.tooltip-outter {
  position: relative;
  background-color: #fff;
}
.tooltip-icon {
  width: 10px;
  position: relative;
}
.tooltip-icon span {
  font-size: 14px;
}
.tooltip-data {
  position: absolute;
  bottom: 100%;
  left: 15%;
  background: rgba(26, 25, 77, 0.9);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(26 25 77 / 12%);
  color: #fff;
  font-size: 12px;
  line-height: normal;
  max-width: 300px;
  padding: 10px;
  z-index: 10001;
  word-wrap: break-word;
  width: 300px;
  display: none;
}
.tooltip-icon:hover .tooltip-data {
  display: block;
}
.tooltip-icon {
  width: 20px;
}
.select_dropdown {
  display: none;
}
.dropdown-form-outter {
  position: relative;
  margin-bottom: 27px;
  min-height: 34px;
  padding-top: 17px;
}
.dropdown-form {
  border: 2px solid #e8e8ed;
  border-radius: 10px;
  border-top: 0 solid #e8e8ed;
  box-shadow: inset 0 2px #e8e8ed;
  padding: 0 20px;
}
.drop-box {
  align-items: center;
  display: flex;
  height: 34px;
  left: 0;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.dropdown-form .get-first:first-child {
  padding: 37px 0 0;
}
.e-grid .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
.e-grid .e-gridheader .e-rightalign .e-stackedheadercelldiv {
  padding: 0 0.2em 0 0.9em !important;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 9px !important;
}
.ant-table-column-sorter-up.active {
  color: #000;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  max-height: 200px;
  overflow-y: auto;
}
.expandtable span {
  color: #000;
  font-weight: normal;
}
.align-items-start {
  align-items: flex-start;
}
// .upload_file span{
//   color: #000;
//   font-weight: normal;
// }
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
.ant-tag {
  border-radius: 20px !important;
}
.ant-tag-success {
  color: #50a562 !important;
  border-color: #b9f5c6 !important;
}

@root-entry-name: default;